import type { EnvironmentVariables, TBrandShowcase } from '@dx-ui/cpm-mapping-shared';
import { usePreviewSegmentsFromUrl } from '@dx-ui/cpm-mapping-shared';
import type { CpmData, TBrandsQuery } from '@dx-ui/cpm-mapping';
import {
  CpmPage,
  fetchServerCpmPageWithSupportedLanguages,
  FetchServerCpmPage404Error,
} from '@dx-ui/cpm-mapping';
import { isGatedPage, getAccountIdFromPath } from '../utils/gated-page';
import type { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { fetchBrandsConfig } from '../utils/fetch-brand-config';
import { getParams } from '../utils/get-params';
import { type QueryClient, dehydrate } from '@tanstack/react-query';
import { SHOP_FORM_ORIGINAL_OP_NAMES, getServerSideShopFormData } from '@dx-ui/osc-shop-form';
import type { GetCompanyByAccountIdQuery } from '../generated/types';
import {
  serverSideGatedPagesQuery,
  serverSideGetCompanyByAccountIdQuery,
} from '../generated/react-query';
import { GatedModal } from '../components/GatedModal';
import type { FeatureToggle } from '@dx-ui/gql-types';
import {
  GET_LANGUAGE_SELECTOR_ONE_LINK_CONFIG_DOCUMENT_ORIGINAL_OP_NAME,
  serverSideGetLanguageSelectorOneLinkConfigQuery,
} from '@dx-ui/queries-osc-language-selector';
import {
  getCommonServerSideFetches,
  getCommonServerSidePostFetches,
} from '../utils/common-server-side-fetches';
import type { OneLinkConfig } from '@dx-ui/framework-i18n';

interface SuccessProps {
  brandCode: string;
  brandData: TBrandsQuery | null;
  cpmData: CpmData;
  gatedData: GetCompanyByAccountIdQuery | null;
  localeCode: string;
}

async function fetchCpmDataAndGatedData(
  queryClient: QueryClient,
  ctx: GetServerSidePropsContext,
  contentPath: string,
  localeCode: string,
  oneLinkConfig: OneLinkConfig | null
): Promise<[GetCompanyByAccountIdQuery | null, CpmData | null]> {
  const [gatedPagesConfig, cpmData] = await Promise.all([
    serverSideGatedPagesQuery(queryClient),
    fetchServerCpmPageWithSupportedLanguages({
      contentPath,
      localeCode,
      pathname: ctx.resolvedUrl,
      queryClient,
      oneLinkConfig,
    }).catch((error: unknown) => {
      if (error instanceof FetchServerCpmPage404Error) {
        console.error(error.message, JSON.stringify(error.variables)); // eslint-disable-line no-console
        return null;
      }

      console.error('Error in fetchServerCpmPageWithSupportedLanguages', error); // eslint-disable-line no-console
      throw error;
    }),
  ]);

  /**
   * Data for gated pages
   */

  let gatedData = null;

  if (isGatedPage(contentPath, gatedPagesConfig.featureConfigs?.[0]?.config)) {
    const accountId = getAccountIdFromPath(contentPath);

    if (!accountId) {
      throw new Error('invalid URL');
    }

    gatedData = await serverSideGetCompanyByAccountIdQuery(queryClient, {
      accountid: accountId,
      language: localeCode,
    }).catch((error: unknown) => {
      console.error('Error fetching serverSideGetCompanyByAccountIdQuery', error); // eslint-disable-line no-console

      return null;
    });
  }

  return [gatedData, cpmData];
}

export const getServerSideProps: GetServerSideProps<SuccessProps> = async (ctx) => {
  try {
    const commonFetches = await getCommonServerSideFetches(ctx);
    if ('notFound' in commonFetches) return commonFetches;
    const { env, queryClient, localeCode, translations, oneLinkProps, sstLocale } = commonFetches;

    const routePath = ctx.resolvedUrl;
    const isPortfolioHomePage = routePath === '/';
    const showLanguageSelector = isPortfolioHomePage;

    const { brandSlugConfig, featureToggles } = await fetchBrandsConfig(queryClient);
    const { contentPath, redirectPath } = getParams({
      brandSlugConfig,
      localeCode,
      reqPath: ctx.resolvedUrl,
    });

    await getServerSideShopFormData(queryClient, { getTranslateAutocomplete: true }).catch((e) => {
      console.error('Error fetching serverSideShopFormData', e); // eslint-disable-line no-console
    });

    if (contentPath === undefined) {
      return { notFound: true };
    }

    if (localeCode === undefined) {
      return { notFound: true };
    }

    const [gatedData, cpmData] = await fetchCpmDataAndGatedData(
      queryClient,
      ctx,
      contentPath,
      localeCode,
      oneLinkProps.oneLinkConfig
    );

    if (cpmData === null) {
      return { notFound: true };
    }

    const brandCode = cpmData.mappedPage.brandCode;
    const { brandData, brandShowcaseData } = await getCommonServerSidePostFetches(
      queryClient,
      localeCode,
      brandCode,
      cpmData.mappedPage.doesIncludeBrandShowcase
    );

    if (redirectPath) {
      return {
        redirect: {
          statusCode: 301,
          destination: `/${sstLocale}${redirectPath}`,
        },
      };
    }

    if (showLanguageSelector) {
      await serverSideGetLanguageSelectorOneLinkConfigQuery(queryClient).catch((error) => {
        console.log('Error in serverSideGetLanguageSelectorOneLinkConfigQuery', error); // eslint-disable-line no-console
        return null;
      });
    }

    // Limit the number of hosts that are allowed to embed your application.
    // @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/frame-ancestors
    ctx?.res?.setHeader('Content-Security-Policy', `frame-ancestors 'self'`);

    const dehydratedQueryKeyOperationNames = [
      ...SHOP_FORM_ORIGINAL_OP_NAMES,
      GET_LANGUAGE_SELECTOR_ONE_LINK_CONFIG_DOCUMENT_ORIGINAL_OP_NAME,
    ];

    return {
      props: {
        env,
        brandCode,
        brandData,
        cpmData,
        // Added contentPath for easier debugging
        contentPath: cpmData.contentPath,
        gatedData,
        brandShowcaseData,
        localeCode,
        featureToggles,
        dehydratedQueryState:
          // JSON.parse(JSON.stringify()) is hack to serialize undefined
          // https://github.com/TanStack/query/issues/1458#issuecomment-788447705
          JSON.parse(
            JSON.stringify(
              dehydrate(queryClient, {
                shouldDehydrateQuery: (query) => {
                  const queryKey = query.queryKey[0] as { originalOpName?: string };
                  return dehydratedQueryKeyOperationNames.includes(queryKey?.originalOpName || '');
                },
              })
            )
          ),
        ...translations,
        ...oneLinkProps,
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Something went wrong when retrieving data for ${ctx.req.url}`);
    // eslint-disable-next-line no-console
    console.dir(error, { depth: null });
    return { notFound: true };
  }
};

interface LivePageProps {
  brandCode: string;
  brandData: TBrandsQuery;
  cpmData: CpmData;
  gatedData: GetCompanyByAccountIdQuery | null;
  brandShowcaseData: TBrandShowcase;
  localeCode: string;
  featureToggles: FeatureToggle[];
  env: EnvironmentVariables;
}

function LivePage({
  brandCode,
  brandData,
  cpmData,
  gatedData,
  brandShowcaseData,
  localeCode,
  featureToggles,
  env,
}: LivePageProps): JSX.Element {
  const previewSegments = usePreviewSegmentsFromUrl();

  return (
    <>
      <CpmPage
        brandCode={brandCode}
        brandData={brandData}
        cpmData={cpmData}
        corporateAccount={gatedData}
        brandShowcaseData={brandShowcaseData}
        localeCode={localeCode}
        featureToggles={featureToggles}
        env={env}
        previewSegments={previewSegments}
      />
      {gatedData ? (
        <GatedModal domains={gatedData?.altCorporateAccount?.domains} brandCode={brandCode} />
      ) : null}
    </>
  );
}

export default LivePage;
